<template>
  <div>
    <a-descriptions title="配置信息" :column="2">
      <a-descriptions-item label="运营商账户">
        {{ data.carrier_account }}
      </a-descriptions-item>

      <a-descriptions-item label="是否限额">
        {{ data.is_limit_usage }}
      </a-descriptions-item>

      <a-descriptions-item label="运营商套餐">
        {{ data.carrier_product }}
      </a-descriptions-item>

      <a-descriptions-item label="限额值">
        {{ data.limit_usage_value }}
      </a-descriptions-item>

      <a-descriptions-item label="VPDN分组ID">
        {{ data.vpdn_id }}
      </a-descriptions-item>

      <a-descriptions-item label="实名认证">
        {{ data.is_need_verified }}
      </a-descriptions-item>

      <a-descriptions-item label="沉默期(月)">
        {{ data.silent_period }}
      </a-descriptions-item>

      <a-descriptions-item label="是否可续期订购">
        {{ data.renewable }}
      </a-descriptions-item>

      <a-descriptions-item label="沉默期截止时间">
        {{ data.silent_period_end_date }}
      </a-descriptions-item>

      <a-descriptions-item label="是否自动续期">
        {{ data.is_auto_renew }}
      </a-descriptions-item>

      <a-descriptions-item label="沉默期截止行为">
        {{ data.silent_period_end_operate }}
      </a-descriptions-item>

      <a-descriptions-item label="空白卡自动激活套餐">
        {{ data.active_agent_product_name }}
      </a-descriptions-item>

      <a-descriptions-item label="测试流量">
        {{ data.test_traffic }} MB
      </a-descriptions-item>

      <a-descriptions-item label="空白卡定套后沉默期">
        {{ data.convert_silent_period }} 月
      </a-descriptions-item>

      <a-descriptions-item label="测试期(月)">
        {{ data.test_period }}
      </a-descriptions-item>

      <a-descriptions-item label="空白卡定套后限额">
        {{ data.convert_limit_usage_value }} 倍
      </a-descriptions-item>

      <a-descriptions-item label="测试期截止时间">
        {{ data.test_period_end_date }}
      </a-descriptions-item>

      <a-descriptions-item label="空白卡激活周期">
        {{ data.active_agent_product_cycles }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'AgentPurchaseItemConfigureInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
