<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="项目详情"
    >
      <template slot="footer">
        <a-spin :spinning="loading">
          <div>
            <a-space>
              <div v-if="['canceled', 'invalid'].includes(data.status_slug)">
                <a-button
                  type="primary"
                  @click="closeModal"
                >
                  关闭
                </a-button>
              </div>
              <div v-else>
                <a-button @click="closeModal">
                  取消
                </a-button>
              </div>
              <div v-if="hasOperatePermission">
                <div v-if="data.status_slug === 'checking'">
                  <a-button
                    type="primary"
                    @click="showModal('configure')"
                    v-if="isShowMenu('agent_purchase_items/configure')"
                  >
                    通过
                  </a-button>

                  <a-button
                    type="primary"
                    @click="showModal('invalid')"
                    v-if="isShowMenu('agent_purchase_items/invalid')"
                  >
                    无效
                  </a-button>
                </div>

                <div v-if="['allocating', 'part_allocated','all_allocated'].includes(data.status_slug)">
                  <a-button
                    type="primary"
                    @click="showModal('allocation')"
                    v-if="isShowMenu('agent_purchase_items/allocate') && 'allocating' === data.status_slug"
                  >
                    划拨
                  </a-button>

                  <a-button
                    type="primary"
                    @click="showModal('allocation')"
                    v-if="isShowMenu('agent_purchase_items/allocate') && 'part_allocated' === data.status_slug"
                  >
                    继续划拨
                  </a-button>

                  <a-button
                    type="primary"
                    @click="showModal('configure')"
                    v-if="data.status_slug === 'allocating' && isShowMenu('agent_purchase_items/configure')"
                  >
                    重新配置
                  </a-button>

                  <a-button
                    type="primary"
                    @click="showModal('invalid')"
                    v-if="data.status_slug === 'allocating' && isShowMenu('agent_purchase_items/invalid')"
                  >
                    无效
                  </a-button>

                  <a-button
                    type="primary"
                    @click="showModal('back_to_inventory')"
                    v-if="isShowMenu('agent_purchase_items/back_to_inventory') && ['part_allocated', 'all_allocated'].includes(data.status_slug)"
                  >
                    退卡入库
                  </a-button>
                </div>
              </div>
            </a-space>
          </div>
        </a-spin>
      </template>
      <a-spin :spinning="loading">
        <agent-purchase-item-basic-info :data="data" />

        <agent-purchase-item-configure-info
          :data="data"
          v-if="['allocating', 'part_allocated', 'all_allocated'].includes(data.status_slug)"
        />

        <a-descriptions title="项目操作历史" :column="1" />
        <agent-purchase-item-event-list :item-id="id" />
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseItem } from '@/api/agent_purchase_item'
import AgentPurchaseItemBasicInfo from '@/views/agent_purchase_items/show/BasicInfo'
import AgentPurchaseItemConfigureInfo from '@/views/agent_purchase_items/show/ConfigureInfo'
import AgentPurchaseItemEventList from '@/views/agent_purchase_item_events/index'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'ShowAgentPurchaseItem',
  components: {
    AgentPurchaseItemBasicInfo,
    AgentPurchaseItemConfigureInfo,
    AgentPurchaseItemEventList
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    hasOperatePermission() {
      return this.isShowMenu('agent_purchase_items/operate')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showModal(operateType) {
      this.isShow = false
      this.$emit('showModal', operateType)
    },

    fetchData() {
      this.loading = true
      findAgentPurchaseItem(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
