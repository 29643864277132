<template>
  <div>
    <a-descriptions
      title="订单信息"
      :column="2"
      class="simiot-descriptions simiot-descriptions-max-6"
    >
      <a-descriptions-item label="客户名称">
        {{ data.agent_name }}
      </a-descriptions-item>

      <a-descriptions-item label="订单编号">
        {{ data.order_no }}
      </a-descriptions-item>

      <a-descriptions-item label="订单状态">
        {{ data.order_status }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions
      title="项目信息"
      :column="2"
      class="simiot-descriptions simiot-descriptions-max-6"
    >
      <a-descriptions-item label="项目编号">
        {{ data.item_no }}
      </a-descriptions-item>

      <a-descriptions-item label="项目状态">
        {{ data.status }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions
      title="采购详情"
      :column="2"
      class="simiot-descriptions simiot-descriptions-max-6"
    >
      <a-descriptions-item label="套餐名称">
        {{ data.product_name }}
      </a-descriptions-item>

      <a-descriptions-item label="套餐价格(元)">
        {{ data.distributor_price | formatFloat }}
      </a-descriptions-item>

      <a-descriptions-item label="运营商种类">
        {{ data.carrier_type }}
      </a-descriptions-item>

      <a-descriptions-item label="卡品">
        {{ data.card_type }}
      </a-descriptions-item>

      <a-descriptions-item label="用户业务模式">
        {{ data.user_business_type }}
      </a-descriptions-item>

      <a-descriptions-item label="卡板价格">
        {{ data.card_type_price }}
      </a-descriptions-item>

      <a-descriptions-item label="套餐周期">
        {{ data.service_cycle }}
      </a-descriptions-item>

      <a-descriptions-item label="采购卡数(张)">
        {{ data.card_count }}
      </a-descriptions-item>

      <a-descriptions-item label="套餐容量">
        {{ data.package_capacity }}
      </a-descriptions-item>

      <a-descriptions-item label="服务周期数">
        {{ data.service_period }}
      </a-descriptions-item>

      <a-descriptions-item label="语音时长">
        {{ data.voice_capacity }}
      </a-descriptions-item>

      <a-descriptions-item label="备注">
        {{ data.remark }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions
      title="项目金额"
      :column="2"
      class="simiot-descriptions simiot-descriptions-max-6"
    >
      <a-descriptions-item label="套餐费用(元)">
        {{ data.product_fee | formatFloat }}
      </a-descriptions-item>

      <a-descriptions-item label="卡板费用(元)">
        {{ data.card_fee | formatFloat }}
      </a-descriptions-item>

      <a-descriptions-item label="小计(元)">
        {{ data.total_fee | formatFloat }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'AgentPurchaseItemBasicInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
